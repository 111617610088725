var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('FilteringPanelWrapper', {
    attrs: {
      "model": _vm.model,
      "filters": _vm.filters
    },
    on: {
      "get-data": _vm.onFilteringPanelGetData,
      "state-change": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "by-products-tab",
    attrs: {
      "title": _vm.tabs.salesByProduct.title,
      "active": ""
    }
  }, [_c('TableWrapper', {
    ref: "salesByProduct",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.salesByProduct,
      "columns": _vm.salesByProductColumns
    },
    on: {
      "loaded": _vm.onSalesByProductLoad
    }
  })], 1), _c('b-tab', {
    ref: "by-invoices-tab",
    attrs: {
      "title": _vm.tabs.salesByInvoice.title
    }
  }, [_c('TableWrapper', {
    ref: "salesByInvoice",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.salesByInvoice,
      "columns": _vm.salesByInvoiceColumns,
      "slots": ['Invoice Number']
    },
    on: {
      "loaded": _vm.onSalesByInvoiceLoad
    },
    scopedSlots: _vm._u([{
      key: "Invoice Number",
      fn: function fn(props) {
        return _c('div', {}, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onInvoiceClick(props.row);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row["Invoice Number"]) + " ")])], 1);
      }
    }])
  })], 1), _c('b-tab', {
    ref: "by-state-tab",
    attrs: {
      "title": _vm.tabs.salesByState.title
    }
  }, [_c('TableWrapper', {
    ref: "salesByState",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.salesByState,
      "columns": _vm.salesByStateColumns
    },
    on: {
      "loaded": _vm.onSalesByStateLoad
    }
  })], 1), _c('b-tab', {
    ref: "store-transfers-tab",
    attrs: {
      "title": _vm.tabs.storeTransfers.title
    }
  }, [_c('TableWrapper', {
    ref: "storeTransfers",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.storeTransfers,
      "columns": _vm.storeTransfersColumns
    },
    on: {
      "loaded": _vm.onStoreTransfersLoad
    }
  })], 1), _c('b-tab', {
    ref: "store-samples-tab",
    attrs: {
      "title": _vm.tabs.storeSamples.title
    }
  }, [_c('TableWrapper', {
    ref: "storeSamples",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.storeSamples,
      "columns": _vm.storeSamplesColumns
    },
    on: {
      "loaded": _vm.onStoreSamplesLoad
    }
  })], 1), _c('b-tab', {
    ref: "tasting-samples-tab",
    attrs: {
      "title": _vm.tabs.tastingSamples.title
    }
  }, [_c('TableWrapper', {
    ref: "tastingSamples",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.tastingSamples,
      "columns": _vm.tastingSamplesColumns
    },
    on: {
      "loaded": _vm.onTastingSamplesLoad
    }
  })], 1), _c('b-tab', {
    ref: "other-samples-tab",
    attrs: {
      "title": _vm.tabs.otherSamples.title
    }
  }, [_c('TableWrapper', {
    ref: "otherSamples",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.otherSamples,
      "columns": _vm.otherSamplesColumns
    },
    on: {
      "loaded": _vm.onOtherSamplesLoad
    }
  })], 1), _c('b-tab', {
    ref: "sales-summary-tab",
    attrs: {
      "title": _vm.tabs.salesSummary.title
    }
  }, [_c('TableWrapper', {
    ref: "salesSummary",
    attrs: {
      "model": _vm.models.warehouse.salesDetailsReport.salesSummary,
      "columns": _vm.salesSummaryColumns
    },
    on: {
      "loaded": _vm.onSalesSummaryLoad
    }
  }), _c('p', [_c('strong', [_vm._v("Total PG / Tax Withdrawn:")]), _vm._v(" " + _vm._s(_vm.tabs.salesSummary.totalPG)), _c('br'), _c('strong', [_vm._v("Total Tax Due: $")]), _vm._v(" " + _vm._s(_vm.tabs.salesSummary.totalTax)), _c('br'), _c('span', {
    staticClass: "text-secondary"
  }, [_vm._v("* - 682 Evropská 66442 excluded")])])], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    ref: "invoice-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.invoicePreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openInvoiceInNewWindow(_vm.invoicePreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeInvoicePreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('InvoiceSubmissionForm', {
    attrs: {
      "id": _vm.invoicePreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }