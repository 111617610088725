<template>
  <b-card no-body>
    <b-container fluid class="pb-3">
      <b-row class="pt-3">
        <b-col>
          <FilteringPanelWrapper
            :model="model"
            :filters="filters"
            @get-data="onFilteringPanelGetData"
            @state-change="onFilteringPanelStateChange"
          ></FilteringPanelWrapper>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-tabs content-class="mt-3" justified>
            <b-tab
              ref="by-products-tab"
              :title="tabs.salesByProduct.title"
              active
            >
              <TableWrapper
                ref="salesByProduct"
                :model="models.warehouse.salesDetailsReport.salesByProduct"
                :columns="salesByProductColumns"
                @loaded="onSalesByProductLoad"
              >
              </TableWrapper>
            </b-tab>

            <b-tab ref="by-invoices-tab" :title="tabs.salesByInvoice.title">
              <TableWrapper
                ref="salesByInvoice"
                :model="models.warehouse.salesDetailsReport.salesByInvoice"
                :columns="salesByInvoiceColumns"
                @loaded="onSalesByInvoiceLoad"
                :slots="['Invoice Number']"
              >
                <div slot="Invoice Number" slot-scope="props">
                  <!-- :href="`${$client_url}${props.row['route_url']}`"-->
                  <b-link @click="onInvoiceClick(props.row)">
                    {{ props.row["Invoice Number"] }}
                  </b-link>
                </div>
              </TableWrapper>
            </b-tab>

            <b-tab ref="by-state-tab" :title="tabs.salesByState.title">
              <TableWrapper
                ref="salesByState"
                :model="models.warehouse.salesDetailsReport.salesByState"
                :columns="salesByStateColumns"
                @loaded="onSalesByStateLoad"
              >
              </TableWrapper>
            </b-tab>
            <b-tab ref="store-transfers-tab" :title="tabs.storeTransfers.title">
              <TableWrapper
                ref="storeTransfers"
                :model="models.warehouse.salesDetailsReport.storeTransfers"
                :columns="storeTransfersColumns"
                @loaded="onStoreTransfersLoad"
              >
              </TableWrapper>
            </b-tab>
            <b-tab ref="store-samples-tab" :title="tabs.storeSamples.title">
              <TableWrapper
                ref="storeSamples"
                :model="models.warehouse.salesDetailsReport.storeSamples"
                :columns="storeSamplesColumns"
                @loaded="onStoreSamplesLoad"
              >
              </TableWrapper>
            </b-tab>
            <b-tab ref="tasting-samples-tab" :title="tabs.tastingSamples.title">
              <TableWrapper
                ref="tastingSamples"
                :model="models.warehouse.salesDetailsReport.tastingSamples"
                :columns="tastingSamplesColumns"
                @loaded="onTastingSamplesLoad"
              >
              </TableWrapper>
            </b-tab>
            <b-tab ref="other-samples-tab" :title="tabs.otherSamples.title">
              <TableWrapper
                ref="otherSamples"
                :model="models.warehouse.salesDetailsReport.otherSamples"
                :columns="otherSamplesColumns"
                @loaded="onOtherSamplesLoad"
              >
              </TableWrapper>
            </b-tab>
            <b-tab ref="sales-summary-tab" :title="tabs.salesSummary.title">
              <TableWrapper
                ref="salesSummary"
                :model="models.warehouse.salesDetailsReport.salesSummary"
                :columns="salesSummaryColumns"
                @loaded="onSalesSummaryLoad"
              >
              </TableWrapper>

              <p>
                <strong>Total PG / Tax Withdrawn:</strong>
                {{ tabs.salesSummary.totalPG }}<br />
                <strong>Total Tax Due: $</strong>
                {{ tabs.salesSummary.totalTax }}<br />
                <span class="text-secondary"
                  >* - 682 Evropská 66442 excluded</span
                >
              </p>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      ref="invoice-preview-modal"
      centered
      scrollable
      size="lg"
      :title="invoicePreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <InvoiceSubmissionForm :id="invoicePreviewModal.id" action="preview" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openInvoiceInNewWindow(invoicePreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeInvoicePreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import FilteringPanelWrapper from "@/components/DataView/FilteringPanelWrapper";
import TableWrapper from "@/components/DataView/TableWrapper";
import InvoiceSubmissionForm from "@/views/Warehouse/Invoices/InvoiceSubmissionForm";

import models from "@/models";

export default {
  name: "SalesDetailsReport",
  components: {
    FilteringPanelWrapper,
    TableWrapper,
    InvoiceSubmissionForm
  },
  props: {},
  data: function() {
    return {
      invoicePreviewModal: {
        id: "",
        title: ""
      },
      tabs: {
        salesByProduct: {
          title: ""
        },
        salesByInvoice: {
          title: ""
        },
        salesByState: {
          title: ""
        },
        storeTransfers: {
          title: ""
        },
        storeSamples: {
          title: ""
        },
        tastingSamples: {
          title: ""
        },

        otherSamples: {
          title: ""
        },

        salesSummary: {
          title: "",
          totalPG: 0,
          totalTax: 0
        }
      },
      models: models,
      model: models.warehouse.salesDetailsReport,
      filters: [
        "Territory",
        "Market",
        "Warehouse",
        "Distributor",
        "Organic Status",
        "Invoice Date"
      ],
      salesByProductColumns: [
        "Product Name",
        "Bottle Size",
        "Number of Bottles",
        "Number of Cases",
        "Price per Case",
        "Line Cost",
        "Currency",
        "Exchange",
        "Proof Gallons",
        "Wine Gallons",
        "% Sales",
        "Distributor",
        "Market",
        "Warehouse",
        "Organic Status"
      ],
      salesByInvoiceColumns: [
        "Invoice Number",
        "QB Number",
        "Invoice Date",
        "Distributor",
        "Number of Bottles",
        "Number of Cases",
        "Line Cost",
        "Discount",
        "Exchange",
        "Currency",
        "Proof Gallons",
        "Wine Gallons",
        "Market",
        "Distributor",
        "Warehouse",
        "Organic Status"
      ],
      salesByStateColumns: [
        "Country",
        "State",
        "Market",
        "Number of Bottles",
        "Number of Cases",
        "Costs",
        "Discount",
        "Proof Gallons",
        "Wine Gallons",
        "Distributor",
        "% Sales"
      ],
      storeTransfersColumns: [
        "Product Name",
        "Bottle Size",
        "Number of Bottles",
        "Number of Cases",
        "Alcohol %",
        "Proof Gallons",
        "Wine Gallons",
        "Market"
      ],
      storeSamplesColumns: [
        "Product Name",
        "Bottle Size",
        "Number of Bottles",
        "Number of Cases",
        "Alcohol %",
        "Proof Gallons",
        "Wine Gallons",
        "Market"
      ],
      otherSamplesColumns: [
        "Distributor",
        "Product Name",
        "Bottle Size",
        "Number of Bottles",
        "Number of Cases",
        "Alcohol %",
        "Proof Gallons",
        "Wine Gallons",
        "Market"
      ],
      tastingSamplesColumns: [
        "Distributor",
        "Product Name",
        "Bottle Size",
        "Number of Bottles",
        "Number of Cases",
        "Alcohol %",
        "Proof Gallons",
        "Wine Gallons",
        "Market"
      ],
      salesSummaryColumns: [
        "Sales Source",
        "Summary",
        "Number of Bottles",
        "Proof Gallons",
        "Wine Gallons"
      ]
    };
  },
  computed: {},

  created() {},
  methods: {
    onFilteringPanelGetData(payload) {
      console.log("onFilteringPanelGetData", JSON.stringify(payload));

      this.$refs["salesByProduct"].getData(payload);

      this.$refs["salesByInvoice"].getData(payload);

      this.$refs["salesByState"].getData(payload);

      this.$refs["storeTransfers"].getData(payload);

      this.$refs["storeSamples"].getData(payload);

      this.$refs["otherSamples"].getData(payload);

      this.$refs["tastingSamples"].getData(payload);

      this.$refs["salesSummary"].getData(payload);
    },
    onFilteringPanelStateChange() {},
    onSalesByProductLoad(count) {
      this.tabs.salesByProduct.title = `Products Sold (${count})`;
    },
    onSalesByInvoiceLoad(count) {
      this.tabs.salesByInvoice.title = `Invoices (${count})`;
    },
    onSalesByStateLoad(count) {
      this.tabs.salesByState.title = `Sales by State (${count})`;
    },
    onStoreTransfersLoad(count) {
      this.tabs.storeTransfers.title = `Store Transfers (${count})`;
    },
    onStoreSamplesLoad(count) {
      this.tabs.storeSamples.title = `Store Samples (${count})`;
    },
    onOtherSamplesLoad(count) {
      this.tabs.otherSamples.title = `Other Samples (${count})`;
    },
    onSalesSummaryLoad(count) {
      this.tabs.salesSummary.title = `Sales Summary (${count})`;

      let data = this.$refs["salesSummary"].getDataSet();

      if (data.length) {
        const pg = data
          .filter(i => i["Sales Source"] !== "682 Evropská 66442")
          .map(i => parseFloat(i["Proof Gallons"] || 0))
          .reduce((sum, x) => sum + x);

        this.tabs.salesSummary.totalPG = pg.toFixed(2);
        this.tabs.salesSummary.totalTax = (pg * 2.7).toFixed(2);
      } else {
        this.tabs.salesSummary.totalPG = 0;
        this.tabs.salesSummary.totalTax = 0;
      }
    },
    onTastingSamplesLoad(count) {
      this.tabs.tastingSamples.title = `Tasting / Bar Samples (${count})`;
    },
    onInvoiceClick(row) {
      console.log("onInvoiceClick", row);

      this.invoicePreviewModal.id = row["Invoice Number"];
      this.invoicePreviewModal.title = `Invoice #${row["Invoice Number"]}`;

      this.$refs["invoice-preview-modal"].show();
    },
    openInvoiceInNewWindow(invoice_id) {
      let routeData = this.$router.resolve({
        name: "WH Invoice submission",
        params: {
          id: invoice_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep .modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
</style>
